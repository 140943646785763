import React, { useMemo, useState } from "react"
import { Badge, Button, Col, Drawer, Image, Menu, Row, Spin, Tag } from "antd"
import { Link } from "gatsby"
import logo from "../../static/assets/images/logo-orizzontale.svg"
import eventure from "../../static/assets/images/eventure-logo-dx.png"
import {
  BellFilled,
  LogoutOutlined,
  MenuOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { getUserDisplayName, translatePath } from "../utils"
import { useAuth0 } from "@auth0/auth0-react"
import { ROLE_TRAINER, ROLE_VIP } from "../enums/UserRoles"
import { useTranslation } from "react-i18next"
import "./MainMenu.less"
import NotificationFeeds from "./NotificationFeeds"

const { SubMenu } = Menu

const wingColsConfig = {
  sm: 12,
  md: 20,
  lg: 3,
  xl: 3,
  xxl: 3,
}

const wingColsConfigMenu = {
  sm: 12,
  md: 8,
  lg: 6,
  xl: 8,
  xxl: 6,
}

const wingColsConfigLogo = {
  sm: 12,
  md: 24,
  lg: 6,
  xl: 6,
  xxl: 6,
}

const wingColsConfigCenterCol = {
  sm: 12,
  md: 16,
  lg: 12,
  xl: 10,
  xxl: 12,
}

const getUserRoleBadge = role => {
  const roles = {
    [ROLE_TRAINER]: "Trainer",
    [ROLE_VIP]: "VIP",
  }

  return roles[role] ?? null
}

const getUserRoleTag = role => {
  const text = getUserRoleBadge(role)
  return text && <Tag color="#f50">{text}</Tag>
}

export const MobileMenu = ({ user }) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const { isLoading } = useAuth0()
  const { t } = useTranslation()

  const handleClose = () => {
    setShowDrawer(false)
  }

  const userMenu = useMemo(() => {
    if (isLoading) {
      return (
        <Menu.Item key="loading-user">
          <Spin spinning />
        </Menu.Item>
      )
    }

    if (!user) {
      return (
        <Menu.Item key="sign-out">
          <Link to={translatePath("/auth/sign-in")}>{t("menu:signIn")}</Link>
        </Menu.Item>
      )
    }

    return (
      <>
        <Menu.Item
          key="notification"
          icon={<BellFilled />}
          onClick={() => {
            setShowNotification(true)
          }}
        >
          <Link to={translatePath("/#")}>{t("menu:notifications")}</Link>
        </Menu.Item>
        <Menu.Item key="profile" icon={<UserOutlined />}>
          <Link to={translatePath("/me")}>
            {t("menu:profile")} {getUserRoleTag(user.role)}
          </Link>
        </Menu.Item>
        <Menu.Item key="sign-out" icon={<LogoutOutlined />}>
          <Link to={translatePath("/auth/sign-out")}>{t("menu:signOut")}</Link>
        </Menu.Item>
      </>
    )
  }, [isLoading, user])

  return (
    <div className="main-menu mobile">
      <Row justify="space-between">
        <Col>
          <Link to={"/"}>
            <img src={logo} alt="logo" style={{ maxHeight: 60 }} />
          </Link>
        </Col>
        <Col>
          <MenuOutlined
            onClick={() => {
              setShowDrawer(true)
            }}
          />
        </Col>
      </Row>
      <Drawer
        title={null}
        placement="right"
        closable={true}
        onClose={handleClose}
        visible={showDrawer}
      >
        <Menu mode="vertical" theme={"dark"}>
          <Menu.Item key="search" icon={<SearchOutlined />}>
            <Link to={translatePath("/search")}>{t("menu:search")}</Link>
          </Menu.Item>
          <Menu.Item key="home">
            <Link to={"/"}>{t("menu:home")}</Link>
          </Menu.Item>
          <Menu.Item key="onDemand">
            <Link to={translatePath("/on-demand")}>{t("menu:onDemand")}</Link>
          </Menu.Item>
          <Menu.Item key="allCategories">
            <Link to={translatePath("/category/all")}>
              {t("menu:categories")}
            </Link>
          </Menu.Item>
          <Menu.Item key="virtualCourses">
            <Link to={translatePath("/course/all")}>
              {t("menu:virtualCourses")}
            </Link>
          </Menu.Item>
          <Menu.Item key="learningAreas">
            <Link to={translatePath("/learning-areas/all")}>
              {t("menu:learningAreas")}
            </Link>
          </Menu.Item>
          <Menu.Item key="onlineTests">
            <Link to={translatePath("/online-tests/all")}>
              {t("menu:onlineTests")}
            </Link>
          </Menu.Item>
          <Menu.Item key="speakers">
            <Link to={translatePath("/speakers/all")}>
              {t("menu:speakers")}
            </Link>
          </Menu.Item>
          <Menu.Item key="myProgress">
            <Link to={translatePath("/my-progress")}>
              {t("menu:myProgress")}
            </Link>
          </Menu.Item>
          {userMenu}
        </Menu>
      </Drawer>

      {showNotification && (
        <Drawer
          height="100%"
          placement="bottom"
          title={t("label:notifications")}
          visible={true}
          onClose={() => {
            setShowNotification(false)
          }}
        >
          <NotificationFeeds />
        </Drawer>
      )}
    </div>
  )
}

export function MainMenu() {
  const { t } = useTranslation()
  return (
    <Menu mode="horizontal" theme={"dark"}>
      <Menu.Item key="home">
        <Link to={"/"}>{t("menu:home")}</Link>
      </Menu.Item>
      <Menu.Item key="onDemand">
        <Link to={translatePath("/on-demand")}>{t("menu:onDemand")}</Link>
      </Menu.Item>
      <Menu.Item key="allCategories">
        <Link to={translatePath("/category/all")}>{t("menu:categories")}</Link>
      </Menu.Item>
      <Menu.Item key="virtualCourses">
        <Link to={translatePath("/course/all")}>
          {t("menu:virtualCourses")}
        </Link>
      </Menu.Item>
      <Menu.Item key="learningAreas">
        <Link to={translatePath("/learning-areas/all")}>
          {t("menu:learningAreas")}
        </Link>
      </Menu.Item>
      <Menu.Item key="onlineTests">
        <Link to={translatePath("/online-tests/all")}>
          {t("menu:onlineTests")}
        </Link>
      </Menu.Item>
      <Menu.Item key="speakers">
        <Link to={translatePath("/speakers/all")}>{t("menu:speakers")}</Link>
      </Menu.Item>
      <Menu.Item key="myProgress">
        <Link to={translatePath("/my-progress")}>{t("menu:myProgress")}</Link>
      </Menu.Item>
    </Menu>
  )
}

export const DesktopMenu = ({ user }) => {
  const { user: auth0User, isLoading } = useAuth0()
  const { t } = useTranslation()
  const [showNotification, setShowNotification] = useState(false)

  const userMenu = useMemo(() => {
    if (isLoading) {
      return (
        <Menu.Item key="sign-out">
          <Spin spinning />
        </Menu.Item>
      )
    }

    if (!user) {
      return (
        <SubMenu
          key="user-submenu"
          icon={
            <Link to={translatePath("/auth/sign-in")}>
              <UserOutlined style={{ fontSize: "20px" }} />
            </Link>
          }
        >
          <Menu.Item key="sign-out">
            <Link to={translatePath("/auth/sign-in")}>{t("menu:signIn")}</Link>
          </Menu.Item>
        </SubMenu>
      )
    }

    return (
      <SubMenu
        key="user-submenu"
        icon={
          <Badge count={getUserRoleBadge(user.role)}>
            <Link to={translatePath("/me")}>
              <UserOutlined style={{ fontSize: "20px" }} />
            </Link>
          </Badge>
        }
      >
        <Menu.Item key="profile">
          <Link to={translatePath("/me")}>
            {getUserDisplayName({ ...auth0User, ...user })}{" "}
            {getUserRoleTag(user.role)}
          </Link>
        </Menu.Item>
        <Menu.Item key="sign-out">
          <Link to={translatePath("/auth/sign-out")}>{t("menu:signOut")}</Link>
        </Menu.Item>
      </SubMenu>
    )
  }, [user, auth0User, isLoading])

  return (
    <div className="main-menu desktop">
      <Row justify="space-between">
        <Col {...wingColsConfigLogo}>
          <Link to={"/"}>
            <img src={logo} alt="logo" style={{ maxHeight: 60 }} />
          </Link>
        </Col>
        <Col {...wingColsConfigCenterCol}>
          <MainMenu />
        </Col>
        <Col {...wingColsConfigMenu}>
          <Row justify={"end"}>
            <Col>
              <Menu mode="horizontal" theme={"dark"}>
                <Menu.Item key="search">
                  <Link to={translatePath("/search")}>
                    <SearchOutlined style={{ fontSize: "20px" }} />
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="notification"
                  onClick={() => {
                    setShowNotification(true)
                  }}
                >
                  <Badge dot>
                    <BellFilled style={{ fontSize: "20px" }} />
                  </Badge>
                </Menu.Item>
                {userMenu}
              </Menu>
            </Col>
            <Col>
              <Row className={"eventure-header-banner"} align={"middle"}>
                <span>{t("menu:forgedby")}</span>
                <Image
                  preview={false}
                  src={eventure}
                  style={{ maxHeight: 60 }}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {showNotification && (
        <Drawer
          width={500}
          title={t("label:notifications")}
          visible={true}
          onClose={() => {
            setShowNotification(false)
          }}
        >
          <NotificationFeeds />
        </Drawer>
      )}
    </div>
  )
}

export const LandingPageMenu = ({ user }) => {
  const { user: auth0User, isLoading, loginWithRedirect } = useAuth0()
  const { t } = useTranslation()

  const handleSignUp = async () => {
    await loginWithRedirect({
      screen_hint: "signUp",
      ui_language: (process.env.GATSBY_LANG || "it_IT").split("_")[0],
    })
  }

  const handleSignIn = async () => {
    await loginWithRedirect({
      ui_language: (process.env.GATSBY_LANG || "it_IT").split("_")[0],
    })
  }

  const userMenu = useMemo(() => {
    if (isLoading) {
      return <Spin spinning />
    }

    return (
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            className="white"
            onClick={handleSignIn}
          >
            {t("button:signInNow")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            className="purple"
            onClick={handleSignUp}
          >
            {t("button:signUp")}
          </Button>
        </Col>
      </Row>
    )
  }, [user, auth0User, isLoading])

  return (
    <Row
      justify="space-between"
      align="middle"
      gutter={[8, 8]}
      className="landing-menu"
    >
      <Col xs={12} sm={12} md={8} lg={6} xl={4}>
        <Link to={"/landing"}>
          <img src={logo} alt="logo" />
        </Link>
      </Col>
      <Col xs={10} sm={10} md={12} lg={8} xl={8} xxl={6}>
        <div>{userMenu}</div>
      </Col>
    </Row>
  )
}
