import React, { useEffect, useState } from "react"
import "./SubscriptionHintBanner.less"
import { useTranslation } from "react-i18next"
import { Button, Col, Row, Space } from "antd"
import { Link } from "gatsby"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { useAuth0 } from "@auth0/auth0-react"
import { devLog, isSubscriptionValid } from "../utils"
import { useLocation } from "@reach/router"
import { LAWFUL_SUBSCRIPTION_ROLES } from "../enums/UserRoles"

const SubscriptionHintBanner = () => {
  const [{ user, isLoadingUser, userSubscription }] = useGlobalValue()
  const { isAuthenticated, isLoading } = useAuth0()

  const { t } = useTranslation()
  const { pathname } = useLocation()

  const [hasValidSubscription, setHasValidSubscription] = useState(null)

  useEffect(() => {
    setHasValidSubscription(isSubscriptionValid(userSubscription))
  }, [userSubscription])

  if (isLoadingUser || isLoading || !isAuthenticated || !user) {
    return null
  }

  if (hasValidSubscription) {
    return null
  }

  devLog({ pathname, test: /(\/auth|\/subscribe)/.test(pathname) })

  if (/(\/auth|\/subscribe)/.test(pathname)) {
    return null
  }

  if (LAWFUL_SUBSCRIPTION_ROLES.includes(user?.role)) {
    return null
  }

  return (
    <div className="subscription-hint-banner">
      <Row justify="space-around">
        <Col xs={24} sm={24} md={22} lg={22} xl={22} xxl={16}>
          <div style={{ textAlign: "center" }}>
            <Space>
              <Row>
                <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                  <span className="hint-message full">
                    {t("message:subscriptionHintFull")}
                  </span>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                  <span className="hint-message small">
                    {t("message:subscriptionHintSmall")}
                  </span>
                </Col>
              </Row>
              <Button size="small" type="primary" className="purple">
                <Link to="/subscribe">{t("button:subscribeNow")}</Link>
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SubscriptionHintBanner
