import React from "react"
import PropTypes from "prop-types"
import { Affix, Col, Layout as AntLayout, Row } from "antd"
import "./Layout.less"
import MainFooter from "./MainFooter"
import { DesktopMenu, LandingPageMenu, MobileMenu } from "./MainMenu"

const { Header, Sider, Content, Footer } = AntLayout

const Layout = ({ user, children, layout }) => {
  switch (layout) {
    case "landing":
      return (
        <AntLayout style={{ minHeight: "100vh" }}>
          <AntLayout className="site-layout">
            <Affix>
              <div
                className="landing-page-menu"
                style={{ padding: "8px 26px", background: "#000" }}
              >
                <LandingPageMenu user={user} />
              </div>
            </Affix>
            <Content>{children}</Content>
          </AntLayout>
        </AntLayout>
      )
    case "empty":
      return children
    default:
      return (
        <AntLayout style={{ minHeight: "100vh" }}>
          <AntLayout className="site-layout">
            <Header>
              <Row>
                <Col xs={0} sm={0} md={24}>
                  <DesktopMenu user={user}/>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                  <MobileMenu user={user} />
                </Col>
              </Row>
            </Header>
            <Content>{children}</Content>
            <MainFooter />
          </AntLayout>
        </AntLayout>
      )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(["full", "landing", "empty"])
}

export default Layout
