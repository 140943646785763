import React, { useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "./Layout"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { Link, navigate } from "gatsby"
import { LeftCircleOutlined } from "@ant-design/icons"
import ErrorScreen from "./ErrorScreen"
import { LOGIN_REQUIRED } from "../../functions/lib/error-codes"
import { useBrowserDetector } from "../hooks/useBrowserDetector"
import i18n from "i18next"
import LoadingScreen from "./LoadingScreen"
import { devLog, translatePath, uiHandleError } from "../utils"
import { Button, Layout as AntLayout } from "antd"
import SubscriptionHintBanner from "./SubscriptionHintBanner"
import {
  PRIVATE_ACCESS_PATH_REGEX,
  PUBLIC_ACCESS_PATH_REGEX,
} from "../enums/Pathnames"
import wallpaper from "../../static/assets/images/hexagon-bg.jpg"
import GenericHeader from "./GenericHeader"

const titleTemplate = "%s | Eventure"
const siteUrl = process.env.GATSBY_AUTH0_REDIRECT_URI
const PageRoot = ({
  children,
  title,
  description,
  image,
  type,
  showTitle,
  showBackButton,
  isDark,
  noLayout,
  layout,
}) => {
  const [{ user, isLoadingUser }] = useGlobalValue()
  const {
    user: auth0User,
    isAuthenticated,
    isLoading: isLoadingAuth0,
  } = useAuth0()
  const { pathname } = useLocation()

  const { isSSR } = useBrowserDetector()

  const checkBasicProfile = useCallback(() => {
    devLog({ isAuthenticated, isLoadingUser, user })
    try {
      if (isLoadingUser) {
        return
      }

      if (isAuthenticated) {
        if (!PUBLIC_ACCESS_PATH_REGEX.test(window.location.pathname)) {
          if (user && !user.basic_profile) {
            navigate(translatePath("/auth/sign-up"))
          }
        }
      }
    } catch (error) {
      uiHandleError({ error })
    }
  }, [isAuthenticated, isLoadingUser, user])

  // useEffect(() => {
  //   if (isLoadingAuth0 || isLoadingUser) {
  //     return
  //   }
  //
  //   if (isAuthenticated) {
  //     checkBasicProfile()
  //   } else {
  //     const publicAccessPathTester = /^\/(tos|privacy|webinar|landing)/
  //     if (!publicAccessPathTester.test(window.location.pathname)) {
  //       navigate(translatePath("/landing"))
  //     }
  //   }
  // }, [isLoadingUser, isLoadingAuth0, isAuthenticated, user])

  const pageContent = useMemo(() => {
    if (isSSR()) {
      return <LoadingScreen />
    }

    if (isLoadingAuth0) {
      return <LoadingScreen />
    }

    if (!isAuthenticated) {
      if (PRIVATE_ACCESS_PATH_REGEX.test(window.location.pathname)) {
        return <ErrorScreen error={new Error(LOGIN_REQUIRED)} />
      }
    }

    if (isAuthenticated && isLoadingUser) {
      return <LoadingScreen />
    }

    const content = (
      <>
        {showTitle && (
          <>
            <GenericHeader
              data={{
                title: title,
                wallpaper: wallpaper,
              }}
            />
            <div className="page-back">
              <LeftCircleOutlined
                style={{ fontSize: "35px" }}
                onClick={() => {
                  navigate(-1)
                }}
              />
            </div>
          </>
        )}
        {!showTitle && showBackButton && (
          <div className="page-back">
            <LeftCircleOutlined
              style={{ fontSize: "35px" }}
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        )}
        {children}
      </>
    )

    return (
      <div className={isDark ? "page-root dark" : "page-root light"}>
        {noLayout ? (
          <AntLayout theme={isDark ? "dark" : "light"}>{content}</AntLayout>
        ) : (
          <Layout user={user || auth0User} layout={layout}>
            {content}
          </Layout>
        )}
      </div>
    )
  }, [isLoadingUser, isAuthenticated, isSSR, isLoadingAuth0])

  return (
    <>
      <Helmet title={title} titleTemplate={titleTemplate}>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta property="og:url" content={`${siteUrl}${pathname}`} />
        <meta property="og:type" content={type || "website"} />
        {title && <meta property="og:title" content={title} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {image && <meta property="og:image" content={image} />}
        <meta name="twitter:card" content="summary_large_image" />

        {title && <meta name="twitter:title" content={title} />}
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        {image && <meta name="twitter:image" content={image} />}
      </Helmet>
      {pageContent}
      <SubscriptionHintBanner />
    </>
  )
}

PageRoot.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(["full", "landing", "empty"]),
}

PageRoot.defaultProps = {
  title: i18n.t("seo:title"),
  description: i18n.t("seo:description"),
  image: process.env.GATSBY_AUTH0_REDIRECT_URI + "/assets/images/logo.png",
  layout: "full",
  showTitle: false,
  showBackButton: false,
  isDark: true,
  noLayout: false,
  // image: "https://via.placeholder.com/350x150",
}

export default PageRoot
