import React from "react"
import { Col, Layout as AntLayout, Row } from "antd"
import logo from "../../static/assets/images/logo-completo.svg"
import "./MainFooter.less"
import i18n from "i18next"
import { useTranslation } from "react-i18next"
import bg from "../../static/assets/images/hexagon-bg.jpg"
import { DesktopMenu, MainMenu } from "./MainMenu"

const footerColSettings = {
  xs: 24,
  md: 6,
  lg: 4
}

const footerColSettingsLogo = {
  xs: 24,
  md: 24,
  lg: 8
}

const footerColSettingsData = {
  xs: 12,
  md: 6,
  lg: 4
}

const { Footer } = AntLayout

const profileMenuList = [
  {
    key: "/me/profile",
    name: i18n.t("menu:settings"),
    description: i18n.t("menu:settingsDescription")
  },
  {
    key: "/help",
    name: i18n.t("menu:helpDesk"),
    description: i18n.t("menu:helpDeskDescription")
  },
  {
    key: "/me/billing/payment-method/list",
    name: i18n.t("menu:billing"),
    description: i18n.t("menu:billingDescription")
  },
  {
    key: "/me/billing",
    name: i18n.t("menu:billingHistory"),
    description: i18n.t("menu:billingHistoryDescription")
  },
  {
    key: "/me/subscription",
    name: i18n.t("menu:subscription"),
    description: i18n.t("menu:subscriptionDescription")
  }
]

const MainFooter = () => {
  const { t } = useTranslation()
  return (
    <Footer
      className={"main-footer"}
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%) , url(${bg}) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(61,61,61,1) 100%)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Row className="footer-wrapper">
        <Col span={24}>
          <div className="container-footer-logo">
            <img src={logo} alt="logo" className="footer-logo" />
          </div>
        </Col>
      </Row>
      <Row className={"footer-menu-tab"} justify={"center"}>
        <Col span={24}>
          <MainMenu />
        </Col>
      </Row>
    </Footer>
  )
}

export default MainFooter
